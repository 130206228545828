import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	let forms = document.querySelectorAll("form");
	if (forms){
		setupFloatLabel(forms);
	}
};

export const setupFloatLabel =  (forms) => {
	forms.forEach(el => {
		el.classList.add("floatlabel-active");
		new FloatLabels(".float-wrap");
	});
};

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});